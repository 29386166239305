const cities = [
  { name: "Thành phố Hồ Chí Minh", keyword: "Thành phố Hồ Chí Minh" },
  { name: "Hà Nội", keyword: "Hà Nội" },
  { name: "Đà Nẵng", keyword: "Đà Nẵng" },
  { name: "Thừa Thiên-Huế", keyword: "Huế" },
  { name: "An Giang", keyword: "An Giang" },
  { name: "Bà Rịa-Vũng Tàu", keyword: "Vũng Tàu" },
  { name: "Bắc Giang", keyword: "Bắc Giang" },
  { name: "Bắc Kạn", keyword: "Bắc Kạn" },
  { name: "Bạc Liêu", keyword: "Bạc Liêu" },
  { name: "Bắc Ninh", keyword: "Bắc Ninh" },
  { name: "Bến Tre", keyword: "Ben Tre" },
  { name: "Bình Định", keyword: "Bình Định" },
  { name: "Bình Dương", keyword: "Binh Duong" },
  { name: "Bình Thuận", keyword: "Phan Thiết" },
  { name: "Cà Mau", keyword: "Ca Mau" },
  { name: "Cần Thơ", keyword: "Cần Thơ" },
  { name: "Cao Bằng", keyword: "Cao Bằng" },
  { name: "Điện Biên", keyword: "Điện Biên Phủ" },
  { name: "Đồng Nai", keyword: "Bien Hoa City" },
  { name: "Gia Lai", keyword: "Gia Lai" },
  { name: "Hà Giang", keyword: "Ha Giang" },
  { name: "Hà Tĩnh", keyword: "Hà Tĩnh" },
  { name: "Hải Dương", keyword: "Thành Phố Hải Dương" },
  { name: "Hải Phòng", keyword: "Hải Phòng" },
  { name: "Hòa Bình", keyword: "Hòa Bình" },
  { name: "Hưng Yên", keyword: "Hưng Yên" },
  { name: "Kon Tum", keyword: "Kon Tum" },
  { name: "Lai Châu", keyword: "Lai Chau" },
  { name: "Lâm Đồng", keyword: "Dalat" },
  { name: "Lạng Sơn", keyword: "Lạng Sơn" },
  { name: "Lào Cai", keyword: "Lào Cai" },
  { name: "Long An", keyword: "Long An" },
  { name: "Nam Định", keyword: "Nam Định" },
  { name: "Ninh Bình", keyword: "Ninh Bình" },
  { name: "Phú Thọ", keyword: "Phú Thọ" },
  { name: "Quảng Ngãi", keyword: "Thành phố Quảng Ngãi" },
  { name: "Quảng Trị", keyword: "Quang Tri" },
  { name: "Sóc Trăng", keyword: "Sóc Trăng" },
  { name: "Sơn La", keyword: "Sơn La" },
  { name: "Tây Ninh", keyword: "Tây Ninh" },
  { name: "Thái Bình", keyword: "Thái Bình" },
  { name: "Thái Nguyên", keyword: "Thái Nguyên" },
  { name: "Thanh Hóa", keyword: "Thanh Hóa" },
  { name: "Trà Vinh", keyword: "Trà Vinh" },
  { name: "Tuyên Quang", keyword: "Tuyên Quang" },
  { name: "Vĩnh Long", keyword: "Vĩnh Long" },
  { name: "Yên Bái", keyword: "Yên Bái" },
];

export default cities;
